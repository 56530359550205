import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { distinctUntilChanged } from "rxjs/operators";

import { FixturePopupComponent } from ".";
import { selectFixturePopupData, FixturePopupFeatureState } from "../../state/fixture-popup";

@Injectable({
    providedIn: "root"
})
export class FixturePopupDialogService {
    constructor(private store: Store<FixturePopupFeatureState>, private dialog: MatDialog) {}

    initialize() {
        this.store
            .select(selectFixturePopupData)
            .pipe(distinctUntilChanged((x, y) => !!x === !!y))
            .subscribe((data) => {
                if (data) {
                    this.dialog.open(FixturePopupComponent, { data, disableClose: true, maxWidth: "90vw", panelClass: "mt-4" });
                } else {
                    this.dialog.closeAll();
                }
            });
    }
}
