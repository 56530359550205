import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { User } from "@ops/state";

import { AppConfigService } from "./app-config.service";

@Injectable()
export class AppInsightsService {
    instance: ApplicationInsights;

    private routerSubscription: Subscription;
    private globalProperties: { [key: string]: string } = {};

    constructor(private appConfigService: AppConfigService, private router: Router) {}

    initialize() {
        this.instance = new ApplicationInsights({
            config: {
                instrumentationKey: this.appConfigService.config.appInsightsInstrumentationKey,
                disableCorrelationHeaders: false
            }
        });

        this.instance.loadAppInsights();
        this.instance.context.application.ver = this.appConfigService.config.buildVersionNumber;

        this.instance.trackPageView();

        this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const activatedComponents = this.getActivatedComponents(this.router.routerState.snapshot.root);
            const paramsFromSnapshot = this.findParamsFromRoute(this.router.routerState.snapshot.root);
            Object.keys(paramsFromSnapshot).forEach((k) => {
                this.globalProperties[k] = paramsFromSnapshot[k];
            });
            if (activatedComponents) {
                const props = this.addGlobalProperties(paramsFromSnapshot);
                this.logPageView(`${activatedComponents} ${this.getRouteTemplate(this.router.routerState.snapshot.root)}`, event.urlAfterRedirects, props);
            }
        });
    }

    findParamsFromRoute(snapshot: ActivatedRouteSnapshot): { [key: string]: string } {
        const params: { [key: string]: string } = {};
        let current = snapshot.firstChild;
        do {
            Object.keys(current.params).forEach((k) => {
                params[k] = current.params[k];
            });
            current = current.firstChild;
        } while (current);
        return params;
    }

    getActivatedComponents(snapshot: ActivatedRouteSnapshot): string {
        const components: any[] = [];
        if (snapshot.children) {
            snapshot.children.forEach((child: any) => {
                const activatedChild = this.getActivatedSnapshot(child);
                components.push(activatedChild.component);
            });
        }
        return components.map((c) => c.componentName || c.name).join(" > ");
    }

    private getActivatedSnapshot(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedSnapshot(snapshot.firstChild);
        }
        return snapshot;
    }

    logPageView(name: string, url?: string, properties?: { [key: string]: string }, duration?: number) {
        this.instance.trackPageView({
            name: name,
            uri: url,
            properties: {
                ...this.addGlobalProperties(properties),
                duration: duration
            }
        });
    }

    logEvent(name: string, properties?: { [key: string]: string }) {
        this.instance.trackEvent(
            {
                name: name
            },
            this.addGlobalProperties(properties)
        );
    }

    startEvent(name: string) {
        this.instance.startTrackEvent(name);
    }

    stopEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        this.instance.stopTrackEvent(name, this.addGlobalProperties(properties), measurements);
    }

    logInfo(message: string) {
        this.instance.trackTrace({
            message: "web-ui, " + message,
            severityLevel: SeverityLevel.Information
        });
    }

    logError(error: Error, properties?: { [key: string]: string }) {
        this.instance.trackException({
            exception: error,
            properties: this.addGlobalProperties(properties)
        });
    }

    setGlobalProperty(key: string, value: string) {
        this.globalProperties[key] = value;
    }

    removeGlobalProperty(key: string) {
        delete this.globalProperties[key];
    }

    private addGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
        if (!properties) {
            properties = {};
        }
        Object.keys(this.globalProperties).forEach((k) => {
            properties[k] = this.globalProperties[k];
        });
        return properties;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = "";
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }
        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }
        return path;
    }

    setUserContext(user?: User) {
        this.instance.setAuthenticatedUserContext(user?.userId.toString());
        this.instance.addTelemetryInitializer((envelope) => {
            const tags = envelope.tags;
            if (tags && tags["ai.user.authUserId"] && user) {
                tags["ai.user.id"] = user.fullName;
                tags["ai.session.id"] = tags["ai.user.authUserId"];

                const telemetryItem = envelope.data.baseData;
                telemetryItem.properties = telemetryItem.properties || {};
                telemetryItem.properties["department"] = user.department;
                telemetryItem.properties["companyName"] = user.companyName;
            }
        });
    }
}
