import { Fixture, Voyage } from "../../fixture/shared/models";
import { AppState } from "../model";

export type FixturePopupState = Readonly<{
    data: FixturePopupData | undefined;
    animatingSaveButton: boolean;
    unlockOnClose: boolean;
}>;

export type FixturePopupData = Readonly<{
    fixture: Fixture;
    voyage: Voyage;
    field: string;
    header: string;
    onSave: SaveHandler;
}>;

export type SaveHandler = (fixture: Fixture, voyage: Voyage) => void;

export type FixturePopupFeatureState = AppState & Readonly<{ fixturePopup: FixturePopupState }>;

export const initialState: FixturePopupState = {
    data: undefined,
    animatingSaveButton: false,
    unlockOnClose: false
};
