import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { Enumeration, ReferenceDataService } from "../../../shared/reference-data";
import {
    ActivityForm,
    FixtureFeatureState,
    selectCurrentDestinationActivityForm,
    selectCurrentVoyageActivityAvailableActivityTypes,
    selectCurrentVoyageActivityIsTypeDropdownReadonly
} from "../../state";
import { FixturePopupTab } from "../models/fixture-popup-tab";

@Component({
    selector: "ops-fixture-popup-activity-tab-header",
    templateUrl: "./fixture-popup-activity-tab-header.component.html",
    styleUrls: ["./fixture-popup-activity-tab-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupActivityTabHeaderComponent implements OnInit {
    form$: Observable<FormGroupState<ActivityForm>>;
    isInvalid$: Observable<boolean>;
    isReadOnly$: Observable<boolean>;
    activityDropdownTypes$: Observable<Enumeration[]>;

    @Input() tab: FixturePopupTab;

    constructor(private store: Store<FixtureFeatureState>, private referenceDataService: ReferenceDataService) {}

    ngOnInit(): void {
        this.form$ = this.store.select(selectCurrentDestinationActivityForm, this.tab.activityInfo);
        this.isReadOnly$ = this.store.select(selectCurrentVoyageActivityIsTypeDropdownReadonly, this.tab.activityInfo).pipe(map((isReadOnly) => isReadOnly || this.tab.isReadOnly));
        this.isInvalid$ = combineLatest([this.isReadOnly$, this.form$]).pipe(map(([isReadOnly, form]) => !isReadOnly && !form.isValid));
        this.activityDropdownTypes$ = this.referenceDataService.getBerthActivityTypes().pipe(
            switchMap((activityTypes) =>
                this.store.select(selectCurrentVoyageActivityAvailableActivityTypes, {
                    ...this.tab.activityInfo,
                    allTypes: activityTypes
                })
            )
        );
    }
}
