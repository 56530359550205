<div class="tabs-container">
    <p-tabView (onChange)="onTabChange($event)">
        <p-tabPanel *ngFor="let tab of tabs; let index = index" [header]="tab.title" [selected]="tab.isActive"
            [headerStyleClass]="(headerStyleClasses$ | async)[index]">
            <ng-template pTemplate="header">
                <ng-container [ngSwitch]="tab.type">
                    <ops-fixture-popup-tab-header *ngSwitchCase="'simple'" [tab]="tab"></ops-fixture-popup-tab-header>
                    <ops-fixture-popup-tab-header *ngSwitchCase="'destination'"
                        [tab]="tab"></ops-fixture-popup-tab-header>
                    <ops-fixture-popup-activity-tab-header *ngSwitchCase="'activity'"
                        [tab]="tab"></ops-fixture-popup-activity-tab-header>
                    <div *ngSwitchDefault>Tab header not found for type {{ tab.type }}</div>
                </ng-container>
            </ng-template>
            <div class="tab-panel-container">
                <ops-fixture-popup-comments *ngIf="tab.title === 'Comments'" [form]="tab.form"></ops-fixture-popup-comments>
                <ops-fixture-popup-fixture-info *ngIf="tab.title === 'Fixture Info'" [form]="tab.form"
                    [fixture]="tab.fixture"></ops-fixture-popup-fixture-info>
                <ops-fixture-popup-laytime-events *ngIf="tab.activityInfo" [activityInfo]="tab.activityInfo"
                    [isReadOnly]="tab.isReadOnly"></ops-fixture-popup-laytime-events>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>