import { Injectable } from "@angular/core";

import { FixturePopupTabCreator } from "./fixture-popup-tab-creator";
import { mapNextPortInfo } from "../../../fixtures/mappers/laytime-events.mapper";
import { Destination, Fixture, Voyage } from "../../shared/models";
import { FixturePopupActivityInfo, FixturePopupTab, FixturePopupTabType } from "../models/fixture-popup-tab";

declare type CreateTabFunction = (title: string, type: FixturePopupTabType, activityInfo: FixturePopupActivityInfo) => FixturePopupTab;

@Injectable({
    providedIn: "root"
})
export class FixturePopupActivitiesTabCreator implements FixturePopupTabCreator {
    createTabs(fixture: Fixture, voyage: Voyage, field: string, isReadOnly: boolean): FixturePopupTab[] {
        const result: FixturePopupTab[] = [];
        const nextPortInfo = mapNextPortInfo(fixture.fixtureType.name, fixture.fixtureStatus.name, fixture.division.name, voyage.destinations);
        for (const destination of voyage.destinations) {
            const locationName = destination.location?.displayName;
            if (!locationName) {
                continue;
            }
            const createTab = (title: string, type: FixturePopupTabType, activityInfo: FixturePopupActivityInfo) => ({
                title,
                type,
                isActive: type === "activity" && ["nextPort", "nextPortOperation", "lastLaytimeEvent"].includes(field) && activityInfo.activityId === nextPortInfo?.activityId,
                isReadOnly,
                fixture,
                activityInfo
            });
            result.push(...this.createActivityTabs(destination, createTab));
        }
        return result;
    }

    private createActivityTabs(destination: Destination, createTab: CreateTabFunction) {
        const result: FixturePopupTab[] = [];
        let isFirstActivity = true;
        for (const berth of destination.berths) {
            const berthName = berth.name?.length ? berth.name : "Berth";
            for (const activity of berth.cargoBerthActivities) {
                const activityInfo = {
                    destinationId: destination.id,
                    berthId: berth.id,
                    activityId: activity.id
                };
                if (isFirstActivity) {
                    result.push(createTab(destination.location.displayName, "destination", activityInfo));
                    isFirstActivity = false;
                }
                result.push(createTab(berthName, "activity", activityInfo));
            }
        }
        return result;
    }
}
