import { Actions, createEffect, ofType } from "@ngrx/effects";
import { On, createAction, on, props } from "@ngrx/store";
import { concat, of } from "rxjs";
import { catchError, exhaustMap, filter, map, switchMap, take, tap } from "rxjs/operators";

import { FixturePopupState, FixturePopupData, SaveHandler, initialState } from "./state";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { FixtureHttpService } from "../../fixture/services/fixture-http.service";
import { VoyageDataService } from "../../fixture/services/voyage-data.service";
import { FixtureDataInterop } from "../../fixture/state";
import { routerSetCurrentFixtureAction } from "../../fixture/state/fixture/routing";
import { GENERIC_ERROR_MESSAGE, NotificationService } from "../../shared";

/* ACTIONS */
const OPEN_FIXTURE_POPUP_NAME = "[Fixture Popup] Open";

export const openFixturePopupAction = createAction(OPEN_FIXTURE_POPUP_NAME, props<{ fixtureId: string; header: string; field: string; onSave: SaveHandler }>());
export const openFixturePopupSuccessAction = createAction(`${OPEN_FIXTURE_POPUP_NAME} Success`, props<FixturePopupData>());
export const openFixturePopupFailAction = createAction(`${OPEN_FIXTURE_POPUP_NAME} Fail`, props<{ error: Error }>());
export const openFixturePopupLockSuccessAction = createAction(`${OPEN_FIXTURE_POPUP_NAME} Lock Success`);
export const openFixturePopupLockFailAction = createAction(`${OPEN_FIXTURE_POPUP_NAME} Lock Fail`, props<{ error: Error }>());

/* REDUCERS */
export const openFixturePopupSuccessReducer: On<FixturePopupState> = on(openFixturePopupSuccessAction, (_, action) => ({
    ...initialState,
    data: {
        fixture: action.fixture,
        voyage: action.voyage,
        field: action.field,
        header: action.header,
        onSave: action.onSave
    }
}));

export const openFixturePopupLockSuccessReducer: On<FixturePopupState> = on(openFixturePopupLockSuccessAction, (state) => ({
    ...state,
    unlockOnClose: true
}));

/* EFFECTS */
export const openFixturePopupEffect$ = (actions$: Actions, fixtureDataService: FixtureDataService, fixtureDataInterop: FixtureDataInterop, voyageDataService: VoyageDataService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(openFixturePopupAction),
            tap(() => fixtureDataInterop.init()),
            exhaustMap(({ fixtureId, header, field, onSave }) =>
                fixtureDataService.load(fixtureId).pipe(
                    switchMap((fixture) =>
                        concat(
                            of(routerSetCurrentFixtureAction({ fixtureId: fixture.fixtureId })),
                            voyageDataService.current$.pipe(
                                filter((voyage) => !!voyage),
                                take(1),
                                map((voyage) => openFixturePopupSuccessAction({ fixture, voyage, header, field, onSave }))
                            )
                        )
                    ),
                    catchError((error) => of(openFixturePopupFailAction({ error })))
                )
            )
        )
    );

export const openFixturePopupSuccessEffect$ = (actions$: Actions, fixtureHttpService: FixtureHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(openFixturePopupSuccessAction),
            filter(({ fixture }) => fixture && !fixture.lockedBy),
            exhaustMap(({ fixture }) =>
                fixtureHttpService.lock(fixture.fixtureId).pipe(
                    map(() => openFixturePopupLockSuccessAction()),
                    catchError((error) => of(openFixturePopupLockFailAction({ error })))
                )
            )
        )
    );

export const openFixturePopupFailEffect$ = (actions$: Actions, notificationService: NotificationService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(openFixturePopupFailAction),
                tap(() => notificationService.error("Failed to load fixture.", GENERIC_ERROR_MESSAGE))
            ),
        { dispatch: false }
    );

export const openFixturePopupLockFailEffect$ = (actions$: Actions, notificationService: NotificationService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(openFixturePopupLockFailAction),
                tap(() => notificationService.error("Failed to lock fixture.", GENERIC_ERROR_MESSAGE))
            ),
        { dispatch: false }
    );
