import { MaritimeDateRange } from "@maritech/maritime-date";

import { formatDateTime } from "./formatters";
import { NextPortInfo } from "../models/next-port-info.model";

export const mapNextPortInfo = (type: string, status: string, division: string, destinations: any[]): NextPortInfo | null => {
    if (type !== "Voyage" || !destinations) {
        return null;
    }
    for (const destination of destinations ?? []) {
        for (const berth of destination.berths ?? []) {
            for (const activity of berth.cargoBerthActivities ?? []) {
                const hasSailed = !!activity?.laytimeEvents?.some((le: any) => isFinalLaytimeEvent(le, division));
                if (!activity?.laytimeEvents || !hasSailed) {
                    return createNextPortInfo(status, destination, activity);
                }
            }
        }
    }
    return null;
};

export const mapLaycanFaded = (type: string, status: string, division: string, destinations: any[]) => {
    if (type !== "Voyage" || ["Ops Closed", "Closed", "Cancelled"].includes(status)) {
        return false;
    }
    let lastLoadActivity;
    for (const destination of destinations ?? []) {
        if (!lastLoadActivity) {
            for (const berth of destination?.berths ?? []) {
                for (const activity of berth?.cargoBerthActivities ?? []) {
                    if (getActivityType(activity) === "Load") {
                        lastLoadActivity = activity;
                    }
                }
            }
        }
    }
    return !!lastLoadActivity?.laytimeEvents?.find((le: any) => isFinalLaytimeEvent(le, division));
};

const createNextPortInfo = (status: string, destination: any, activity: any): NextPortInfo => {
    const name = destination.location?.displayName ?? "";
    const timeZone = destination.location?.timeZone ?? "utc";
    return {
        name,
        isNextPortMissing: status === "Open" && !name?.length,
        operation: getActivityType(activity),
        activityId: activity.id,
        eta: (destination.etaRange && buildEtaString(destination.etaRange, timeZone)) ?? "",
        lastLaytimeEvent: buildLastLaytimeEvent(activity?.laytimeEvents, timeZone)
    };
};

// as this code is used for both rows in Fixtured grid as well as fixtures themselves, this line takes care of both cases
const getActivityType = (activity: any) => activity?.type?.name ?? activity?.type ?? "";

const isFinalLaytimeEvent = (laytimeEvent: any, division: string) =>
    laytimeEvent?.type?.name === (division === "Specialised Products" ? "Hoses Disconnected" : "Sailed") && !!laytimeEvent.eventDate;

const buildEtaString = (etaRange: any, timeZone: string) =>
    etaRange.from !== etaRange.to ? MaritimeDateRange.fromDates(etaRange.from, etaRange.to, { zone: timeZone }).toMaritimeString() : formatDateTime(etaRange.from, timeZone);

const buildLastLaytimeEvent = (laytimeEvents: any[], timeZone: string) => {
    if (!laytimeEvents?.length) {
        return "";
    }
    for (let index = laytimeEvents.length - 1; index >= 0; index--) {
        const laytimeEvent = laytimeEvents[index];
        if (laytimeEvent.type?.name && laytimeEvent.eventDate) {
            const eventName = laytimeEvent.type?.name;
            const eventDate = formatDateTime(laytimeEvent.eventDate, timeZone);
            return `${eventName} ${eventDate}`;
        }
    }
    return "";
};
